import { put, takeLatest, call, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { logoutSuccess, selectUser } from "./userReducer";

function* logoutSaga(): SagaIterator {
  try {
    // TODO: fix this
    // const user = yield select(selectUser);

    // const encodedEmail = encodeURIComponent(user?.email);

    // console.log("first", user, encodedEmail);

    // const response = yield call(
    //   fetch,
    //   `https://oauth2.googleapis.com/revoke?email=${encodedEmail}`,
    //   { method: "POST" }
    // );

    // console.log("response", response);

    // if (!response.ok) {
    //   throw new Error("Failed to log out");
    // }

    localStorage.removeItem("user/accessToken");
    console.log("User logged out");
    yield put(logoutSuccess());

    window.scrollTo(0, 0);
    window.location.reload();
  } catch (error) {
    console.error("Logout error", error);
  }
}

export function* initUserSagas(): SagaIterator {
  yield takeLatest("LOGOUT", logoutSaga);
}
