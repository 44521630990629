import React from "react";
import * as DOMPurify from "dompurify";
import { StyledWrapper } from "./styles";

export const TextViewer = ({ htmlContent }: any) => {
  const sanitizedContent = DOMPurify.sanitize(htmlContent);

  return (
    <StyledWrapper>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </StyledWrapper>
  );
};
