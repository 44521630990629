/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Penicon2 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`penicon-2 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.40824 25.5606L13.3582 18.6106M22.1102 15.52L20.0297 21.7613C19.9965 21.8613 19.9397 21.9519 19.864 22.0253C19.7884 22.0986 19.6961 22.1527 19.5951 22.1828L6.8418 25.9734C6.7269 26.0076 6.60485 26.0095 6.48891 25.9791C6.37298 25.9487 6.26762 25.887 6.18429 25.8009C6.10095 25.7147 6.04285 25.6074 6.01629 25.4905C5.98973 25.3736 5.99574 25.2517 6.03366 25.138L10.1748 12.7167C10.2045 12.6275 10.253 12.5457 10.3171 12.4769C10.3812 12.4082 10.4593 12.354 10.5461 12.318L16.4292 9.89581C16.5488 9.84641 16.6803 9.83354 16.8072 9.8588C16.9341 9.88407 17.0507 9.94635 17.1423 10.0378L21.9518 14.8484C22.0381 14.9345 22.0986 15.043 22.1266 15.1617C22.1545 15.2803 22.1489 15.4044 22.1102 15.52Z"
        stroke="#000C25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M22.2388 15.1353L25.3272 12.0458C25.7367 11.6362 25.9667 11.0808 25.9667 10.5016C25.9667 9.92248 25.7367 9.36703 25.3272 8.95744L23.012 6.64004C22.8092 6.43713 22.5684 6.27616 22.3033 6.16634C22.0383 6.05652 21.7542 6 21.4673 6C21.1804 6 20.8963 6.05652 20.6312 6.16634C20.3662 6.27616 20.1254 6.43713 19.9225 6.64004L16.8341 9.72954M15.6743 16.2929C15.5222 16.1409 15.3415 16.0202 15.1428 15.9379C14.944 15.8557 14.7309 15.8133 14.5158 15.8134C14.3007 15.8134 14.0876 15.8559 13.8889 15.9382C13.6902 16.0206 13.5096 16.1413 13.3575 16.2935C13.2054 16.4457 13.0848 16.6263 13.0025 16.8251C12.9202 17.0238 12.8779 17.2369 12.8779 17.452C12.878 17.6672 12.9204 17.8802 13.0028 18.0789C13.0852 18.2777 13.2059 18.4582 13.358 18.6103C13.6653 18.9175 14.0821 19.09 14.5166 19.0899C14.9511 19.0898 15.3677 18.9171 15.6749 18.6098C15.982 18.3025 16.1545 17.8857 16.1544 17.4513C16.1543 17.0168 15.9816 16.6001 15.6743 16.2929Z"
        stroke="#000C25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
