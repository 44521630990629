import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IImage, IGalleryState } from "./gallery.types";

// Initial state
const initialState: IGalleryState = {
  images: [],
  loading: false,
  selectedImageID: "",
  activeImage: {
    title: "",
    content: "",
    published: false,
  },
};

// Create slice
const gallerySlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    fetchGallery() {
      // This is just a trigger for the fetchGallerySaga
    },
    createImage: (state, action: PayloadAction<IImage>) => {
      state.images.push(action.payload);
    },
    readImage: (state, action: PayloadAction<string>) => {
      state.activeImage = state.images.find(
        (image: IImage) => image.id === action.payload
      );
    },
    updateImage: (state, action: PayloadAction<IImage>) => {
      const index = state.images.findIndex(
        (image: IImage) => image.id === action.payload.id
      );
      if (index !== -1) {
        state.images[index] = action.payload;
      } else {
        console.warn("Attempted to update a gallery that doesn't exist");
      }
    },
    deleteImage(state, action: PayloadAction<string>) {
      state.images = state.images.filter(
        (image: IImage) => image.id !== action.payload
      );
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedImageID(state, action: PayloadAction<string>) {
      state.selectedImageID = action.payload;
    },
    setGallery(state, action: PayloadAction<IImage[]>) {
      state.images = action.payload;
    },
    setGalleryCoverImageURL(state, action: PayloadAction<string>) {
      if (state.activeImage) {
        state.activeImage.image_url = action.payload;
      }
    },
  },
});

export const galleryReducer = gallerySlice.reducer;
export const {
  fetchGallery,
  setGallery,
  setLoading,
  setSelectedImageID,
  createImage,
  readImage,
  updateImage,
  deleteImage,
  setGalleryCoverImageURL,
} = gallerySlice.actions;
