import React, { useState } from "react";
import { Menuicon1 } from "../../icons/Menuicon1";
import "./style.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const Menu = ({ device = "web" }) => {
  const [activeSection, setActiveSection] = useState(null);
  const navigate = useNavigate();

  const scrollToSection = (sectionId: any) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
    setActiveSection(sectionId);
  };

  const handleMenuClick = async (e: any, sectionId: any) => {
    await navigate("/home");
    e.preventDefault(); // Prevent default anchor behavior
    scrollToSection(sectionId);
  };

  const reloadPage = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };

  return (
    <div className={`menu device-${device}`}>
      {device === "web" && (
        <div className="nav-bar">
          <a
            href="/home"
            className={`nav-item  ${activeSection === "home" ? "bold" : ""}`}
            onClick={() => reloadPage()}
          >
            Home
          </a>

          <a
            href="#services"
            className={`nav-item  ${
              activeSection === "services" ? "bold" : ""
            }`}
            onClick={(e) => handleMenuClick(e, "services")}
          >
            Services
          </a>

          <a
            href="#about"
            className={`nav-item  ${activeSection === "about" ? "bold" : ""}`}
            onClick={(e) => handleMenuClick(e, "about")}
          >
            About
          </a>

          <Link
            to="/blogs"
            className={`nav-item  ${activeSection === "news" ? "bold" : ""}`}
          >
            Blogs
          </Link>
        </div>
      )}
    </div>
  );
};
