import {
  useForm,
  isNotEmpty,
  isEmail,
  isInRange,
  hasLength,
  matches,
} from "@mantine/form";
import {
  Button,
  Group,
  TextInput,
  NumberInput,
  SimpleGrid,
  Textarea,
  Flex,
} from "@mantine/core";
import "./style.css";

export function ContactForm({ isMob }: any) {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      job: "",
      email: "",
      favoriteColor: "",
      age: 18,
    },

    validate: {
      name: hasLength({ min: 2, max: 10 }, "Name must be 2-10 characters long"),
      job: isNotEmpty("Enter your current job"),
      email: isEmail("Invalid email"),
      favoriteColor: matches(
        /^#([0-9a-f]{3}){1,2}$/,
        "Enter a valid hex color"
      ),
      age: isInRange(
        { min: 18, max: 99 },
        "You must be 18-99 years old to register"
      ),
    },
  });

  return (
    <div id="quote" className={`contact-form is-mob-${isMob}`}>
      <div className="title">
        <div className="title-2">Grow with us</div>
        <p className="paragraph">
          Help us with a few details and someone from our team will reach out
          shortly.
        </p>
      </div>

      <form onSubmit={form.onSubmit(() => alert("submity"))}>
        <SimpleGrid cols={{ base: 1, sm: 1, lg: 2 }}>
          <TextInput
            label="Full Name"
            placeholder="Name"
            withAsterisk
            {...form.getInputProps("name")}
          />

          <TextInput
            label="Company Name"
            placeholder="Company Name"
            {...form.getInputProps("company_name")}
          />

          <TextInput
            label="Your email"
            placeholder="Your email"
            withAsterisk
            {...form.getInputProps("email")}
          />

          <NumberInput
            label="Number of Employees"
            placeholder="Company size"
            {...form.getInputProps("company_size")}
          />
        </SimpleGrid>

        <Textarea
          label="Message"
          placeholder="Your Message"
          withAsterisk
          mt="md"
          // {...form.getInputProps("favoriteColor")}
          mb="xl"
        />

        <p className="by-submitting-this">
          <span className="text-wrapper-3">
            By submitting this form, you confirm that you have read and
            understood Etlyn’s{" "}
          </span>
          <span className="text-wrapper-4">Privacy Policy</span>
          <span className="text-wrapper-3">
            . This site is protected by reCAPTCHA and the Google{" "}
          </span>
          <span className="text-wrapper-4">Privacy Policy</span>
          <span className="text-wrapper-3"> and </span>
          <span className="text-wrapper-4">Terms of Service</span>
          <span className="text-wrapper-3"> apply.</span>
        </p>

        <Group justify="center" mt="md">
          <Button type="submit" color="#0d2a7d">
            Submit
          </Button>
        </Group>
      </form>
    </div>
  );
}
