import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchServices,
  deleteService,
  reorderServices,
  reorderServicesRequest,
} from "./services.slice";
import { IService } from "./services.types";
import { Button, Paper, Group, Loader, Stack, Divider } from "@mantine/core";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { IconGripVertical } from "@tabler/icons-react";
import { AddServiceForm } from "./services.form";

const ControlContainer = styled.div`
  padding: 20px;
`;

export const ServicesControl: React.FC = () => {
  const dispatch = useDispatch();
  const { services, loading } = useSelector(
    (state: RootState) => state.services
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingService, setEditingService] = useState<IService | null>(null);

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  const handleEdit = (service: IService) => {
    setEditingService(service);
    setIsEditing(true);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteService(id));
  };

  const handleCreate = () => {
    setEditingService(null);
    setIsEditing(true);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedServices = reorder(
      services,
      result.source.index,
      result.destination.index
    ) as IService[];

    dispatch(reorderServicesRequest(reorderedServices));
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <ControlContainer>
        <Button onClick={handleCreate} mb="md">
          ახალი სერვისი
        </Button>
        {isEditing && (
          <AddServiceForm
            service={editingService}
            onCancel={() => setIsEditing(false)}
          />
        )}
        <Paper withBorder shadow="md" p="md" mt="md">
          <div>
            <h2>სერვისები</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {services.map((service, index) => (
                      <Draggable
                        key={service.id.toString()}
                        draggableId={service.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Divider pb="sm" />
                            <Group justify="space-between">
                              <Group>
                                <div {...provided.dragHandleProps}>
                                  <IconGripVertical />
                                </div>
                                <span>{service.label}</span>
                              </Group>
                              <Group>
                                <Button
                                  onClick={() => handleEdit(service)}
                                  variant="outline"
                                  size="xs"
                                  ml="sm"
                                >
                                  რედაქტირება
                                </Button>
                                <Button
                                  onClick={() => handleDelete(service.id!)}
                                  variant="outline"
                                  size="xs"
                                  color="red"
                                  ml="sm"
                                >
                                  წაშლა
                                </Button>
                              </Group>
                            </Group>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Paper>
      </ControlContainer>
    </div>
  );
};
