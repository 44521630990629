import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyInfo, ICompanyInfoState } from "./companyInfo.types";

// Initial state
const initialState: ICompanyInfoState = {
  loading: true,
  data: {
    id: "string",
    name: "string",
    website: "string",
    description: "string",
    logo_url: "string",
    features: ["string"],
    social_media: [
      {
        platform: "string",
        url: "string",
      },
    ],
    location: "string",
    contact_number: "string",
    contact_email: "string",
    service_email: "string",
    service_number: "string",
    seo_keywords: ["string"],
    metadata: {},
  },
};

// Create slice
const companyInfoSlice = createSlice({
  name: "companyInfo",
  initialState,
  reducers: {
    fetchCompanyInfo() {
      // This is just a trigger for the fetchCompanyInfosSaga
    },
    updateCompanyInfo(state, action: PayloadAction<ICompanyInfo>) {
      state.data = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const companyInfoReducer = companyInfoSlice.reducer;
export const { fetchCompanyInfo, updateCompanyInfo, setLoading } =
  companyInfoSlice.actions;
