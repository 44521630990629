import React, { useState } from "react";
import { FeatureCard } from "../FeatureCard";
import { Tabs } from "@mantine/core";
import "./style.css";
import { useControlXState } from "@etlyn/control-x";

export const ServiceSection: React.FC<any> = ({ device }) => {
  const servicesState = useControlXState("services-core");
  const [activeTab, setActiveTab] = useState<string | null>("0" ?? null);

  const handleTabChange = (value: string | null) => {
    setActiveTab(value);
  };
  return (
    <div id="services" className={`service-section device-${device}`}>
      <div className="services">Services</div>
      <Tabs
        defaultValue="0"
        onChange={handleTabChange}
        variant="unstyled"
        styles={{
          list: { backgroundColor: "#fff", borderRadius: 20 },
        }}
      >
        <Tabs.List
          grow
          justify="center"
          className={device === "mob" ? "tabs-list-mob" : ""}
        >
          {servicesState.services.map((service, index) => (
            <Tabs.Tab
              key={service.id}
              value={`${index}`}
              style={
                activeTab === `${index}`
                  ? {
                      backgroundColor: "#000c25",
                      color: "#efefef",
                      borderRadius: 20,
                    }
                  : {}
              }
              color="#000c25"
            >
              {service.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {servicesState.services.map((service, index) => (
          <Tabs.Panel key={service.id} value={`${index}`} pt="xs">
            <FeatureCard
              className="feature-card-instance"
              device={device === "mob" ? "mob" : "web"}
              image={service.content.image}
              heading={service.content.heading}
              subheading={service.content.subheading}
              desc={service.content.desc}
              highlights={service.content.highlights}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};
