import { Burger, Image, Menu, Text, rem } from "@mantine/core";
import { IconLogout, IconX } from "@tabler/icons-react";
import { usePlatform } from "../hooks/usePlatform";

export const Header = ({
  user,
  handleLogOut,
  company,
  isPanelOpen,
  openPanel,
}: any) => {
  const platform = usePlatform();

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "16px",
        marginRight: "36px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Image src={company?.logo_url} mah={30} />
      </div>

      <Menu shadow="md" width={180}>
        {platform === "web" && (
          <Menu.Target>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <h1>👋 {localStorage.getItem("user/given_name")}</h1>
              <img
                src={localStorage.getItem("user/picture") ?? ""}
                alt="it's me"
                style={{
                  borderRadius: "50%",
                  width: "36px",
                  height: "36px",
                  objectFit: "cover",
                }}
              />
            </div>
          </Menu.Target>
        )}

        {!isPanelOpen ? (
          <Burger
            opened={false}
            onClick={openPanel}
            hiddenFrom="sm"
            size="sm"
          />
        ) : (
          <IconX onClick={openPanel} />
        )}

        <Menu.Dropdown>
          <Menu.Item
            color="red"
            leftSection={
              <IconLogout style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={handleLogOut}
          >
            Log out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
