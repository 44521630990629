import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  color: #333;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  margin-right: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const CompanyList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const CompanyItem = styled.li`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

export const CompanyTitle = styled.h3`
  color: #007bff;
`;

export const CompanyInfo = styled.p`
  color: #666;
`;
