import { fork } from "redux-saga/effects";
import { initUserSagas } from "./user/userSagas";
import { initBlogSagas } from "../features/blogs";
import { initServiceSagas } from "../features/company_services";
import { initGallerySagas } from "../features/gallery/gallery.sagas";
import { initCompanyInfoSagas } from "../features/company_info";

export function* rootSaga() {
  yield fork(initUserSagas);
  yield fork(initBlogSagas);
  yield fork(initServiceSagas);
  yield fork(initGallerySagas);
  yield fork(initCompanyInfoSagas);
  // ... other rootSaga
}
