import { AspectRatio, Container, BackgroundImage, Flex } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

interface IProps {
  onDelete: (name: string) => void;
  imgName: string;
  imgURL: any;
  fallbackSrc: any;
  showDeleteIcon: boolean;
}

export const ImageViewer = ({
  onDelete,
  imgURL,
  imgName,
  fallbackSrc,
  showDeleteIcon = true,
}: IProps) => {
  return (
    <Container fluid h="100%" w="100%">
      <AspectRatio ratio={16 / 9}>
        <BackgroundImage src={imgURL} radius="lg">
          {showDeleteIcon && (
            <Flex justify="flex-end" align="flex-end" h="100%">
              <IconTrash
                stroke={2}
                color="#E9143B"
                onClick={() => onDelete(imgName)}
                style={{
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "flex-end",
                  zIndex: 100,
                  alignItems: "center",
                  flexShrink: 1,
                  width: 48,
                  height: 48,
                  borderRadius: 24,
                  padding: 12,
                  margin: 16,
                }}
              />
            </Flex>
          )}
        </BackgroundImage>
      </AspectRatio>
    </Container>
  );
};
