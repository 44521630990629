import React, { useEffect, useState } from "react";
import { SimpleGrid, Stack } from "@mantine/core";
import { Header } from "../../components/Header";
import { useNavigate } from "react-router";
import { BlogCard, useControlXState } from "@etlyn/control-x";

function BlogList() {
  const blogsState = useControlXState("blogs-core");
  const navigate = useNavigate();

  const [device, setDevice] = useState("web");

  useEffect(() => {
    if (window.screen.width < 768) {
      setDevice("mob");
    }
  }, []);

  return (
    <>
      <Stack
        style={{
          marginLeft: device === "web" ? 140 : 32,
          marginRight: device === "web" ? 140 : 32,
          marginTop: 100,
          paddingBottom: 200,
        }}
      >
        <SimpleGrid cols={device === "web" ? 3 : 1}>
          {blogsState.blogs
            .filter((blog) => blog.published)
            .map((blog, index) => (
              <BlogCard
                key={index}
                title={blog.title}
                text={blog.content}
                onClick={() => {
                  navigate(`/blogs/${blog.id}`);
                }}
                imgURL={blog.cover_image_url}
              />
            ))}
        </SimpleGrid>
      </Stack>
    </>
  );
}

export default BlogList;
