import {
  Group,
  Avatar,
  Text,
  Accordion,
  Center,
  Stack,
  Space,
} from "@mantine/core";

const faqList = [
  {
    id: "websiteDevelopmentProcess",
    label: "How does your website development process work?",
    content:
      "Our process starts with understanding your business needs, followed by designing a custom strategy. Our developers then craft clean, optimized code ensuring fast loading times and a seamless user experience. We prioritize responsive design, cross-browser compatibility, and thorough testing before launch.",
  },
  {
    id: "searchEngineRankingImprovement",
    label: "Can you help improve my website's search engine ranking?",
    content:
      "Absolutely. Our SEO team uses data-driven tactics to boost your search visibility. This includes targeted keyword research, technical optimizations, and local SEO strategies to attract organic traffic and establish your authority online.",
  },
  {
    id: "hiringEtlynCost",
    label: "How much does it cost to hire Etlyn?",
    content:
      "Our pricing is customized based on the scope of your project and the specific services you require. To give you an accurate quote, we start with a consultation to understand your needs and business goals. This approach ensures that we can offer a tailored solution that fits your budget while delivering maximum value. Please contact us for a detailed estimate.",
  },
  {
    id: "mobileAppDevelopment",
    label: "Do you offer mobile app development for both iOS and Android?",
    content:
      "Yes, we develop custom mobile applications that are compatible across platforms, ensuring a broader reach. Our development process includes concept creation, rigorous testing, and app store optimization for maximum visibility.",
  },
  {
    id: "ongoingSupportAndUpdates",
    label:
      "How do you ensure ongoing support and updates for digital projects?",
    content:
      "We offer ongoing maintenance and updates for all our projects. Our team continuously monitors performance, making necessary adjustments to ensure your digital assets remain competitive and up-to-date.",
  },
];

function FAQ({ isMob }: any) {
  const items = faqList.map((item) => (
    <Accordion.Item value={item.id} key={item.label}>
      <Accordion.Control>
        <Text>{item.label}</Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm">{item.content}</Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Stack mt="xl" mb="xl">
      <Space h="xl" />
      <Space h="xl" />

      <Center mb="xl">
        <Text size="xl" fw={700}>
          Frequently Asked Questions
        </Text>
      </Center>
      <Accordion
        chevronPosition="right"
        variant="contained"
        w={!isMob ? 1000 : 360}
        mt="md"
      >
        {items}
      </Accordion>
    </Stack>
  );
}

export default FAQ;
