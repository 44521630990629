import { Badge, Card, Image, Text } from "@mantine/core";
import { TextViewer } from "./TextEditor/TextViewer";

export function BlogCard({ title, text, imgURL, onClick, isPublished }: any) {
  return (
    <Card
      shadow="sm"
      padding="xl"
      mah={380}
      mih={200}
      miw={200}
      component="a"
      onClick={onClick}
    >
      {isPublished && (
        <Badge
          color="green"
          style={{ position: "absolute", bottom: 12, right: 12 }}
        >
          Published
        </Badge>
      )}
      <Card.Section>
        <Image src={imgURL} h={200} alt="The cover image" />
      </Card.Section>
      <Text fw={500} size="lg" mt="md">
        {title}
      </Text>
      <TextViewer htmlContent={text.substring(0, 70)} />
    </Card>
  );
}
