import { useEffect, useRef, useState } from "react";
// import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticated, setUser } from "../../redux/user/userReducer";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useScript } from "./useScript";
import { Center } from "@mantine/core";
import { Image, Menu, Text, rem } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { API } from "../../api";

const Login = () => {
  const routeOrigin = useSelector(
    (state: any) => state.router.location.state?.from.pathname
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googlebuttonref = useRef(null);

  useScript("https://accounts.google.com/gsi/client", () => {
    window.google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_OATH_CLIENT_ID,
      auto_select: false,
      callback: onSuccess,
      error_callback: onError,
    });

    window.google?.accounts?.id?.renderButton(googlebuttonref.current, {
      size: "medium",
    });
  });

  function onError(err: string) {
    console.log("onError", err);
  }

  function onSuccess(response: any) {
    const userCred = response.credential;
    const user = jwtDecode(userCred);
    if (user) {
      localStorage.setItem("user/accessToken", userCred);
      // @ts-ignore
      localStorage.setItem("user/given_name", user?.given_name);
      // @ts-ignore
      localStorage.setItem("user/picture", user?.picture);

      dispatch(setAuthenticated(true));

      dispatch(setUser(user));

      if (routeOrigin) {
        navigate(routeOrigin, { replace: true });
      } else {
        navigate("/admin", { replace: true });
      }
    }
  }

  const [company, setCompany] = useState<any>();
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(
          `/companies/${process.env.REACT_APP_COMPANY_ID}`
        );
        setCompany(response.data);
      } catch (error) {
        console.error("Failed to fetch current companies:", error);
      }
    })();
  }, []);

  return (
    <Center
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 32,

        marginTop: "150px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Image src={company?.logo_url} mah={30} />
      </div>

      <div
        style={{
          width: 300,
          height: 200,
          background: "white",
          boxShadow: "0px 0.875px 15.75px rgba(134, 144, 160, 0.12)",
          borderRadius: 5.25,
          overflow: "hidden",

          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          padding: 48,
        }}
      >
        <h1
          style={{
            color: "#101112",
            fontSize: 18,
            fontWeight: "500",
          }}
        >
          Sign in
        </h1>
        <div ref={googlebuttonref}></div>
      </div>
    </Center>
  );
};

export default Login;
