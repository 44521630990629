import { useEffect, useState } from "react";
import {
  Button,
  Group,
  Text,
  Image,
  SimpleGrid,
  Container,
  Modal,
  Stack,
  Center,
  AspectRatio,
} from "@mantine/core";
import { API } from "../../api";
import { IconTrash } from "@tabler/icons-react";
import { FileWithPath } from "@mantine/dropzone";
import ImageSelector from "../../components/ImageSelector/ImageSelector";
import { useDisclosure } from "@mantine/hooks";

export const GalleryControl = () => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [reload, setReload] = useState(false);
  const [deleteModalStatus, deleteModalHandlers] = useDisclosure(false);
  const [selectedImgName, setSelectedImgName] = useState("");

  useEffect(() => {
    API.get("/gallery/")
      .then((response) => {
        setFiles(response.data);
      })
      .catch((error) => {
        console.log("Error get list images:", error);
      });
  }, [reload]);

  const handleUpload = (files: any) => {
    files?.map((file: any) => {
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      API.post("/gallery/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log("Image uploaded successfully:", response.data?.url);
        })
        .catch((error) => {
          console.log("Error uploading image:", error);
        });
    });

    setReload(!reload);
  };

  const handleDelete = () => {
    API.delete(`/gallery/${selectedImgName}`)
      .then((response) => {
        console.log("Image deleted successfully:", response.data);
        setReload(!reload);
        deleteModalHandlers.close();
      })
      .catch((error) => {
        console.log("Error deleting image:", error);
      });
  };

  return (
    <Container fluid pb="xl">
      <Group justify="space-between" mb="xl" mr="xl">
        <Text size="xl" inline>
          ფოტოები:
        </Text>
      </Group>

      <SimpleGrid cols={3}>
        <ImageSelector onUpload={handleUpload} />

        {files.map((file, index) => {
          // @ts-ignore
          const imageUrl = file?.url ? file?.url : URL.createObjectURL(file);

          return (
            <Stack key={index} mih={240} maw={400}>
              <Center mb={-32}>
                <IconTrash
                  stroke={1}
                  color="red"
                  style={{
                    backgroundColor: "#F2f2f2",
                    width: 36,
                    height: 36,
                    borderRadius: 24,
                    padding: 8,
                    zIndex: 10,
                  }}
                  onClick={() => {
                    setSelectedImgName(file.name);
                    deleteModalHandlers.open();
                  }}
                />
              </Center>

              <AspectRatio ratio={16 / 9}>
                <Image
                  src={imageUrl}
                  onLoad={() => URL.revokeObjectURL(imageUrl)}
                  style={{
                    objectFit: "scale-down",
                  }}
                />
              </AspectRatio>

              {/* <Group justify="flex-start" mt="md">
                <Text>{file?.name}</Text>
              </Group> */}
            </Stack>
          );
        })}
      </SimpleGrid>

      <Modal
        opened={deleteModalStatus}
        onClose={deleteModalHandlers.close}
        size="auto"
      >
        <Stack justify="space-between" p={20} mih={180} maw={400}>
          <Text>გთხოვთ დაადასტუროთ, გსურთ ფოტოს წაშლა?</Text>

          <Group gap={48} justify="center">
            <Button
              onClick={deleteModalHandlers.close}
              variant="outline"
              miw={150}
            >
              გაუქმება
            </Button>
            <Button onClick={handleDelete} miw={150}>
              წაშლა
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Container>
  );
};
