import React, { useState, ChangeEvent } from "react";
import {
  TextInput,
  Button,
  List,
  Flex,
  Group,
  Stack,
  ActionIcon,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";

interface ListInputFormProps {
  label: string;
  data: string[];
  value: string[];
  onChange: (value: string[]) => void;
  placeholder: string;
  isMultiSelect?: boolean;
}

export const ListInputForm: React.FC<ListInputFormProps> = ({
  label,
  data,
  value,
  onChange,
  placeholder,
  isMultiSelect = false,
}) => {
  const [inputValue, setInputValue] = useState("");

  const addItem = () => {
    if (inputValue.trim()) {
      onChange([...value, inputValue]);
      setInputValue("");
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const removeItem = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <Stack>
      <Flex align="center" justify="center" gap="lg">
        <TextInput
          label={label}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          w={600}
        />
        <Button mt="lg" onClick={addItem}>
          + დამატება
        </Button>
      </Flex>

      <List mt="xs">
        {value.map((item, index) => (
          <Group key={index}>
            <List.Item>{item}</List.Item>
            <ActionIcon
              variant="transparent"
              color="red"
              onClick={() => removeItem(index)}
            >
              <IconX />
            </ActionIcon>
          </Group>
        ))}
      </List>
    </Stack>
  );
};
