import { useState } from "react";
import { TextEditor } from "../../components/TextEditor/TextEditor";
import { TextViewer } from "../../components/TextEditor/TextViewer";
import {
  Button,
  Container,
  Group,
  Modal,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedBlogID,
  deleteBlog,
  createBlog,
  IBlogState,
  updateBlog,
  setBlogCoverImageURL,
} from "../blogs";
import { API } from "../../api";
import { FileWithPath } from "@mantine/dropzone";
import { BlogToolBar } from "./blog.toolbar";
import { useDisclosure } from "@mantine/hooks";
import { StyledWrapper } from "../../components/TextEditor/styles";
import { ImageViewer } from "../../components";
import ImageSelector from "../../components/ImageSelector/ImageSelector";

export const BlogEditor = ({ selectedBlogID, setEditorMode }: any) => {
  const { activeBlog } = useSelector(
    (state: { blogs: IBlogState }) => state.blogs
  );
  const [title, setTitle] = useState(activeBlog?.title ?? "");
  const [isPublished, setIsPublished] = useState(
    activeBlog?.published ?? false
  );
  const [isPreview, setIsPreview] = useState(false);
  const dispatch = useDispatch();

  const [img, setImg] = useState<FileWithPath | null>(
    // @ts-ignore
    activeBlog?.cover_image_url
  );

  const [htmlFromEditor, setHtmlFromEditor] = useState(
    activeBlog?.content ?? ""
  );

  const handleDelete = () => {
    activeBlog?.id && dispatch(deleteBlog(activeBlog.id));
    dispatch(setSelectedBlogID(""));
    setEditorMode(false);
  };

  const handleSubmit = async () => {
    const _imgURL =
      // @ts-ignore
      activeBlog?.cover_image_url !== img
        ? await saveImage(img)
        : activeBlog?.cover_image_url;

    if (selectedBlogID === "") {
      dispatch(
        createBlog({
          title: title,
          content: htmlFromEditor,
          cover_image_url: _imgURL,
          published: isPublished,
        })
      );
    } else {
      dispatch(
        updateBlog({
          ...activeBlog,
          title: title,
          content: htmlFromEditor,
          cover_image_url: _imgURL,
          published: isPublished,
        })
      );
    }
    setEditorMode(false);
  };

  const deleteImage = (name: any) => {
    setImg(null);
    if (name) {
      API.delete(`/images/${name}`)
        .then((response) => {
          console.log("Image deleted successfully:", response.data);
        })
        .catch((error) => {
          console.log("Error deleting image:", error);
        });
    }
  };

  const saveImage = async (img: any) => {
    try {
      const r = URL.createObjectURL(img);
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", img);

      const response = await API.post("/images/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        console.log("Image uploaded successfully:", response.data);
      }

      const publishedURL = response.data?.url;

      return publishedURL;
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  const [isPublishModalOpened, handlePublishModal] = useDisclosure(false);

  const [opened, { open, close }] = useDisclosure(false);

  const handlePublish = (e: any) => {
    handlePublishModal.open();
    setIsPublished(e.target.checked);
  };

  return (
    <Container>
      <BlogToolBar
        onBack={() => setEditorMode(false)}
        onSave={handleSubmit}
        onPreview={() => setIsPreview(true)}
        onEdit={() => setIsPreview(false)}
        onDelete={open}
        onPublish={handlePublish}
        isPublished={isPublished}
      />

      <Space h="xl" />

      <Modal
        opened={isPublishModalOpened}
        onClose={handlePublishModal.close}
        size="auto"
      >
        <Stack justify="space-between" p={20} mih={180} maw={400}>
          {isPublished ? (
            <Text>გთხოვთ დაადასტუროთ, გსურთ ბლოგის გამოქვეყნება?</Text>
          ) : (
            <Text>გთხოვთ დაადასტუროთ, გსურთ ბლოგის საიტიდან გათიშვა?</Text>
          )}

          <Group gap={48} justify="center">
            <Button
              onClick={handlePublishModal.close}
              variant="outline"
              miw={150}
            >
              გაუქმება
            </Button>
            <Button onClick={handleSubmit} miw={150}>
              {isPublished ? "გამოქვეყნება" : "გათიშვა"}
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Modal opened={opened} onClose={close} size="auto">
        <Stack justify="space-between" p={20} mih={180} maw={400}>
          <Text>გთხოვთ დაადასტუროთ, გსურთ ბლოგის წაშლა?</Text>

          <Group gap={48} justify="center">
            <Button onClick={close} variant="outline" miw={150}>
              გაუქმება
            </Button>
            <Button onClick={handleDelete} miw={150}>
              წაშლა
            </Button>
          </Group>
        </Stack>
      </Modal>

      {img != null ? (
        <ImageViewer
          imgURL={activeBlog?.cover_image_url}
          onDelete={() => deleteImage(img?.name)}
          imgName={img?.name ?? ""}
          fallbackSrc="https://placehold.co/400x300?text=image"
          showDeleteIcon={!isPreview}
        />
      ) : (
        <ImageSelector
          onUpload={(res: any) => {
            setImg(res[0]);
            dispatch(setBlogCoverImageURL(URL.createObjectURL(res[0])));
          }}
          maxFiles={1}
        />
      )}

      <Space h="lg" />

      {!isPreview ? (
        <TextEditor
          content={activeBlog?.content}
          title={title}
          label="სახელი"
          sublabel="ბლოგი"
          setHtmlFromEditor={setHtmlFromEditor}
          setTitle={setTitle}
          onSubmit={handleSubmit}
        />
      ) : (
        <StyledWrapper>
          <Stack>
            <h1>{title}</h1>
            <div style={{ marginTop: "24px" }}>
              <TextViewer htmlContent={htmlFromEditor} />
            </div>
          </Stack>
        </StyledWrapper>
      )}
    </Container>
  );
};
