import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany, ICompanyState } from "./company.types";

// Initial state
const initialState: ICompanyState = {
  companies: [],
  loading: false,
  selectedCompanyID: "",
  activeCompany: {
    title: "",
    content: "",
    published: false,
  },
};

// Create slice
const companySlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    fetchCompanies() {
      // This is just a trigger for the fetchCompaniesSaga
    },
    createCompany: (state, action: PayloadAction<ICompany>) => {
      state.companies.push(action.payload);
    },
    readCompany: (state, action: PayloadAction<string>) => {
      state.activeCompany = state.companies.find(
        (company: ICompany) => company.id === action.payload
      );
    },
    updateCompany: (state, action: PayloadAction<ICompany>) => {
      const index = state.companies.findIndex(
        (company) => company.id === action.payload.id
      );
      if (index !== -1) {
        state.companies[index] = action.payload;
      } else {
        console.warn("Attempted to update a company that doesn't exist");
      }
    },
    deleteCompany(state, action: PayloadAction<string>) {
      state.companies = state.companies.filter(
        (company) => company.id !== action.payload
      );
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedCompanyID(state, action: PayloadAction<string>) {
      state.selectedCompanyID = action.payload;
    },
    setCompanies(state, action: PayloadAction<ICompany[]>) {
      state.companies = action.payload;
    },
    setCompanyCoverImageURL(state, action: PayloadAction<string>) {
      if (state.activeCompany) {
        state.activeCompany.cover_image_url = action.payload;
      }
    },
  },
});

export const companyReducer = companySlice.reducer;
export const {
  fetchCompanies,
  setLoading,
  setCompanies,
  setSelectedCompanyID,
  createCompany,
  readCompany,
  updateCompany,
  deleteCompany,
  setCompanyCoverImageURL,
} = companySlice.actions;
