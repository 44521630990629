import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IService, IServiceState } from "./services.types";

// Initial state
const initialState: IServiceState = {
  services: [],
  loading: false,
  selectedServiceID: "",
  activeService: {
    id: "",
    label: "",
    content: {
      image: "string",
      heading: "string",
      subheading: "string",
      desc: "string",
      highlights: ["string"],
    },
  },
};

// Create slice
const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    fetchServices() {
      // This is just a trigger for the fetchServicesSaga
    },
    reorderServicesRequest(state, action: PayloadAction<IService[]>) {
      // This action will trigger the saga to call the API
    },
    createService: (state, action: PayloadAction<IService>) => {
      state.services.push(action.payload);
    },
    readService: (state, action: PayloadAction<string>) => {
      state.activeService = state.services.find(
        (service: IService) => service.id === action.payload
      );
    },
    updateService: (state, action: PayloadAction<IService>) => {
      const index = state.services.findIndex(
        (service) => service.id === action.payload.id
      );
      if (index !== -1) {
        state.services[index] = action.payload;
      } else {
        console.warn("Attempted to update a service that doesn't exist");
      }
    },
    deleteService(state, action: PayloadAction<string>) {
      state.services = state.services.filter(
        (service) => service.id !== action.payload
      );
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedServiceID(state, action: PayloadAction<string>) {
      state.selectedServiceID = action.payload;
    },
    setServices(state, action: PayloadAction<IService[]>) {
      state.services = action.payload;
    },
    reorderServices(state, action: PayloadAction<IService[]>) {
      state.services = action.payload;
    },
  },
});

export const serviceReducer = serviceSlice.reducer;
export const {
  fetchServices,
  setLoading,
  setServices,
  setSelectedServiceID,
  createService,
  readService,
  updateService,
  deleteService,
  reorderServices,
  reorderServicesRequest,
} = serviceSlice.actions;
