import styled from "styled-components";

export const StyledWrapper = styled.div`
  h1 {
    font-size: 2em;
    color: #4a4a4a;
  }

  h2 {
    font-size: 1.75em;
    color: #4a4a4a;
  }

  h3 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  h4 {
    font-size: 1.25em;
    color: #6a6a6a;
  }

  p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
    margin-top: 0;
    margin-bottom: 1em;
  }

  /* Styles for unordered lists */
  ul {
    list-style-type: disc; /* Default style, but ensures consistency */
    margin-left: 2em; /* Adjust as needed */
    margin-bottom: 1em;
  }

  /* Styles for ordered lists */
  ol {
    list-style-type: decimal; /* Default style, but ensures consistency */
    margin-left: 2em; /* Adjust as needed */
    margin-bottom: 1em;
  }

  /* Common styles for list items */
  li {
    font-size: 1em; /* Matches paragraph text, adjust as needed */
    line-height: 1.6; /* Matches paragraph text */
    color: #666; /* Matches paragraph text */
  }
`;
