import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader, Container, Title, Button, Space } from "@mantine/core";
import { RootState } from "../../redux/store";
import { fetchCompanyInfo, updateCompanyInfo } from "./companyInfo.slice";
import { ICompanyInfo } from "./companyInfo.types";
import CompanyInfoForm from "./companyInfo.form";

export const CompanyInfoControl: React.FC = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: RootState) => state.companyInfo
  );

  const [formData, setFormData] = useState<Partial<ICompanyInfo>>({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(fetchCompanyInfo());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleArrayChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      // @ts-ignore
      [field]: prev[field]?.map((item, idx) => (idx === index ? value : item)),
    }));
  };

  const handleArrayAdd = (field: string) => {
    setFormData((prev) => ({
      ...prev,
      // @ts-ignore
      [field]: [...(prev[field] || []), ""],
    }));
  };

  const handleArrayRemove = (index: number, field: string) => {
    setFormData((prev) => ({
      ...prev,
      // @ts-ignore
      [field]: prev[field]?.filter((_, idx) => idx !== index),
    }));
  };

  const handleSave = () => {
    if (formData) {
      dispatch(updateCompanyInfo(formData as ICompanyInfo));
      setIsEditing(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Title order={1} size="md">
        Manage Company Information
      </Title>

      <Space h="lg" />

      {!isEditing ? (
        <div>
          <p>Name: {data?.name}</p>
          <p>Website: {data?.website}</p>
          <p>Description: {data?.description}</p>
          <p>Contact Email: {data?.contact_email}</p>
          <p>Contact Number: {data?.contact_number}</p>
          <p>Service Email: {data?.service_email}</p>
          <p>Service Number: {data?.service_number}</p>
          <p>Location: {data?.location}</p>
          <p>Logo URL: {data?.logo_url}</p>
          <p>
            Social Media:{" "}
            {data?.social_media
              ?.map((sm) => `${sm.platform}: ${sm.url}`)
              .join(", ")}
          </p>
          <p>SEO Keywords: {data?.seo_keywords?.join(", ")}</p>
          <p>Metadata: {JSON.stringify(data?.metadata, null, 2)}</p>

          <Space h="lg" />

          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        </div>
      ) : (
        <CompanyInfoForm
          formData={formData}
          handleInputChange={handleInputChange}
          handleSave={handleSave}
          handleArrayChange={handleArrayChange}
          handleArrayAdd={handleArrayAdd}
          handleArrayRemove={handleArrayRemove}
        />
      )}
    </Container>
  );
};
