import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  createService,
  fetchServices,
  deleteService,
  setServices,
  setLoading,
  updateService,
  readService,
  reorderServices,
  reorderServicesRequest,
} from "./services.slice";
import { IService, IServiceState } from "./services.types";
import { API } from "../../api";
import { v4 as uuidv4 } from "uuid";

function* fetchServicesSaga(): SagaIterator {
  try {
    yield put(setLoading(true));
    const response = yield call(API.get, "/services");
    yield put(setServices(response.data));
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error fetching services:", error);
    yield put(setLoading(false));
  }
}

function* reorderServicesSaga(action: PayloadAction<IService[]>): SagaIterator {
  try {
    yield put(setLoading(true));

    const reorderedServiceIds = action.payload.map((service) => service.id);
    const serviceIdsString = reorderedServiceIds.join("%2C");

    console.log(
      "Sending serviceIdsString",
      `/services/reorder/${serviceIdsString}`
    );

    const response = yield call(
      API.put,
      `/services/reorder/${serviceIdsString}`,
      null, // No body is needed
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      yield put(reorderServices(action.payload));
    }
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error reordering services:", error);
    yield put(setLoading(false));
  }
}

function* createServiceSaga(action: PayloadAction<IService>): SagaIterator {
  try {
    yield put(setLoading(true));

    // Generate ID if not provided
    const serviceData: IService = {
      ...action.payload,
      id: action.payload.id || uuidv4(),
    };

    const response = yield call(API.post, "/services", serviceData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("createServiceSaga response", response);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error creating service:", error);
    yield put(setLoading(false));
  }
}

function* readServiceSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoading(true));

    const services = yield select((state: IServiceState) => state.services);
    // const service = services.find((service: IService) => service.id === action.payload);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error readServiceSaga service:", error);
    yield put(setLoading(false));
  }
}

function* updateServiceSaga(action: PayloadAction<IService>): SagaIterator {
  try {
    if (!action.payload.id) {
      console.error(
        "Attempting to update a service without a valid ID:",
        action.payload
      );
      return;
    }
    yield put(setLoading(true));
    const response = yield call(
      API.put,
      `/services/${action.payload.id}`,
      action.payload
    );
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error updating service:", error);
    yield put(setLoading(false));
  }
}

function* deleteServiceSaga(action: PayloadAction<number>): SagaIterator {
  try {
    yield put(setLoading(true));
    yield call(API.delete, `/services/${action.payload}`);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error removing service:", error);
    yield put(setLoading(false));
  }
}

export function* initServiceSagas() {
  yield takeLatest(fetchServices.type, fetchServicesSaga);

  yield takeLatest(createService.type, createServiceSaga);
  yield takeLatest(readService.type, readServiceSaga);
  yield takeLatest(updateService.type, updateServiceSaga);
  yield takeLatest(deleteService.type, deleteServiceSaga);
  yield takeLatest(reorderServicesRequest.type, reorderServicesSaga);

  yield call(fetchServicesSaga);
}
