import { useCallback, useEffect, useState } from "react";

export const useFetchData = (url: string) => {
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true); // Start loading when the function is called
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error("fetchData error", url, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
    return () => {
      // Optional cleanup to avoid setting state on unmounted component
      setData(null);
      setError(null);
    };
  }, [fetchData]);

  return { data, error, loading };
};
