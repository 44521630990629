import React, { useCallback, useEffect, useState } from "react";
import { AppShell, Burger, NavLink } from "@mantine/core";
import { useDispatch } from "react-redux";
import { logout } from "../redux/user/userReducer";
import { Header } from "../components/Header";
import { API } from "../api";
import { features } from "../features";
import { useDisclosure } from "@mantine/hooks";

export function Shell() {
  const [navItems, setNavItems] = useState<any[]>([]);
  const [activeRoute, setActiveRoute] = useState("");
  const [company, setCompany] = useState<any>();
  const dispatch = useDispatch();

  const [opened, { toggle }] = useDisclosure();

  const handleLogOut = () => {
    dispatch(logout());
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(
          `/companies/${process.env.REACT_APP_COMPANY_ID}`
        );
        setCompany(response.data);

        const enabledFeatures = features.filter((feature) =>
          response.data?.features?.find((item: string) => item === feature.name)
        );
        setNavItems(enabledFeatures);

        if (enabledFeatures.length > 0) {
          setActiveRoute(enabledFeatures[0].name);
        }
      } catch (error) {
        console.error("Failed to fetch current companies:", error);
      }
    })();
  }, []);

  const renderActiveComponent = useCallback(() => {
    const activeItem = navItems.find((item) => item.name === activeRoute);
    return activeItem ? activeItem.component : null;
  }, [activeRoute, navItems]);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Header
          company={company}
          handleLogOut={handleLogOut}
          isPanelOpen={opened}
          openPanel={toggle}
        />
      </AppShell.Header>

      <AppShell.Navbar p="md">
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            label={item.label}
            active={item.name === activeRoute}
            rightSection={item.rightSection}
            leftSection={<item.icon size="1rem" stroke={1.5} />}
            onClick={() => setActiveRoute(item.name)}
          />
        ))}
      </AppShell.Navbar>

      <AppShell.Main>{renderActiveComponent()}</AppShell.Main>
    </AppShell>
  );
}
