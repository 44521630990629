import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  createImage,
  fetchGallery,
  deleteImage,
  setGallery,
  setLoading,
  updateImage,
  readImage,
} from "./gallery.slice";
import { IImage, IGalleryState } from "./gallery.types";
import { API } from "../../api";
import { v4 as uuidv4 } from "uuid";

function* fetchGallerySaga(): SagaIterator {
  try {
    yield put(setLoading(true));
    const response = yield call(API.get, "/gallery");
    yield put(setGallery(response.data));
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error fetching gallery:", error);
    yield put(setLoading(false));
  }
}

function* createImageSaga(action: PayloadAction<IImage>): SagaIterator {
  try {
    yield put(setLoading(true));

    // Generate ID if not provided
    const serviceData: IImage = {
      ...action.payload,
      id: action.payload.id || uuidv4(),
    };

    const response = yield call(API.post, "/gallery", serviceData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    yield put(setLoading(false));
  } catch (error) {
    console.error("Error creating service:", error);
    yield put(setLoading(false));
  }
}

function* readImageSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoading(true));

    const gallery = yield select((state: IGalleryState) => state.images);
    // const service = gallery.find((service: IImage) => service.id === action.payload);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error readImageSaga service:", error);
    yield put(setLoading(false));
  }
}

function* updateImageSaga(action: PayloadAction<IImage>): SagaIterator {
  try {
    if (!action.payload.id) {
      console.error(
        "Attempting to update a service without a valid ID:",
        action.payload
      );
      return;
    }
    yield put(setLoading(true));
    const response = yield call(
      API.put,
      `/gallery/${action.payload.id}`,
      action.payload
    );
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error updating service:", error);
    yield put(setLoading(false));
  }
}

function* deleteImageSaga(action: PayloadAction<number>): SagaIterator {
  try {
    yield put(setLoading(true));
    yield call(API.delete, `/gallery/${action.payload}`);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error removing service:", error);
    yield put(setLoading(false));
  }
}

export function* initGallerySagas() {
  yield takeLatest(fetchGallery.type, fetchGallerySaga);

  yield takeLatest(createImage.type, createImageSaga);
  yield takeLatest(readImage.type, readImageSaga);
  yield takeLatest(updateImage.type, updateImageSaga);
  yield takeLatest(deleteImage.type, deleteImageSaga);

  yield call(fetchGallerySaga);
}
