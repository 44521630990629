import React from "react";
import { Checkicon23 } from "../../icons/Checkicon23";
import "./style.css";
import { Flex, Group, Text } from "@mantine/core";
export const FeatureCard = ({
  device,
  className,
  image,
  heading,
  subheading,
  desc,
  highlights = [],
}: any) => {
  return (
    <div className={`feature-card device-2-${device} ${className}`}>
      <div className="subheading">
        <div className="website">{heading}</div>
        <div className="development">{subheading}</div>
      </div>

      <div className="service">
        <img className="image" alt="Image" src={image} />

        <div className="description">
          <p className="at-our-design-agency">{desc}</p>

          <div className="list">
            {highlights.map((item: any, i: number) => (
              <Flex key={i} justify="flex-start" align="flex-start" gap="sm">
                <Checkicon23 className="check-icon" />
                <Text>{item}</Text>
              </Flex>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
