import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createService, updateService } from "./services.slice";
import { IService } from "./services.types";
import {
  Button,
  TextInput,
  Textarea,
  Paper,
  Group,
  Divider,
} from "@mantine/core";
import styled from "styled-components";
import ImageSelector from "../../components/ImageSelector/ImageSelector";
import { FileWithPath } from "@mantine/dropzone";
import { API } from "../../api";
import { ImageViewer } from "../../components";
import { ListInputForm } from "../../components/ListInputForm";

const EditForm = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddServiceFormProps {
  service?: IService | null;
  onCancel: () => void;
}

export const AddServiceForm: React.FC<AddServiceFormProps> = ({
  service,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [editingService, setEditingService] = useState<IService | null>(
    service || {
      label: "",
      id: "",
      content: {
        image: "",
        heading: "",
        subheading: "",
        desc: "",
        highlights: [],
      },
    }
  );
  const [img, setImg] = useState<FileWithPath | null>(null);
  const [showImageSelector, setShowImageSelector] = useState(false);

  const handleSave = async () => {
    const _imgURL = await saveImage(img);

    if (editingService) {
      if (editingService.id) {
        dispatch(
          updateService({
            ...editingService,
            content: { ...editingService.content, image: _imgURL },
          })
        );
      } else {
        dispatch(
          createService({
            ...editingService,
            content: { ...editingService.content, image: _imgURL },
          })
        );
      }
      onCancel();
    }
  };

  const saveImage = async (img: any) => {
    try {
      const formData = new FormData();
      formData.append("file", img);

      const response = await API.post("/images/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        console.log("Image uploaded successfully:", response.data);
      }

      const publishedURL = response.data?.url;

      return publishedURL;
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  const deleteImage = () => {
    setImg(null);
    setShowImageSelector(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingService) {
      setEditingService({ ...editingService, [e.target.name]: e.target.value });
    }
  };

  const handleContentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    if (editingService) {
      setEditingService({
        ...editingService,
        content: { ...editingService.content, [key]: e.target.value },
      });
    }
  };

  const handleHighlightsChange = (values: string[]) => {
    if (editingService) {
      setEditingService({
        ...editingService,
        content: { ...editingService.content, highlights: values },
      });
    }
  };

  return (
    <Paper withBorder shadow="md" p="md" mt="md">
      <Group justify="space-between">
        <h2>{editingService?.id ? "რედაქტირება" : "ახალი სერვისი"}</h2>
        <div onClick={onCancel}>X</div>
      </Group>

      <EditForm>
        <TextInput
          label="ლეიბლი"
          name="label"
          value={editingService?.label || ""}
          onChange={handleChange}
          placeholder="ტექსტი"
        />

        <Group mt="lg">
          {img != null ? (
            <Group mah={400} w={500}>
              <ImageViewer
                imgURL={URL.createObjectURL(img)}
                onDelete={deleteImage}
                imgName={img?.name ?? ""}
                fallbackSrc="https://placehold.co/400x300?text=image"
                showDeleteIcon={!!editingService}
              />
            </Group>
          ) : editingService?.content.image && !showImageSelector ? (
            <>
              <Group mah={400} w={500}>
                <ImageViewer
                  imgURL={editingService?.content.image}
                  onDelete={deleteImage}
                  imgName={""}
                  fallbackSrc="https://placehold.co/400x300?text=image"
                  showDeleteIcon={!!editingService}
                />
              </Group>
            </>
          ) : (
            <ImageSelector onUpload={(_img) => setImg(_img[0])} maxFiles={1} />
          )}
        </Group>

        <TextInput
          label="სათაური"
          name="heading"
          value={editingService?.content.heading || ""}
          onChange={(e) => handleContentChange(e, "heading")}
          placeholder="სათაური"
        />
        <TextInput
          label="ქვესათაური"
          name="subheading"
          value={editingService?.content.subheading || ""}
          onChange={(e) => handleContentChange(e, "subheading")}
          placeholder="ქვესათაური"
        />
        <Textarea
          label="აღწერა"
          name="desc"
          value={editingService?.content.desc || ""}
          onChange={(e) => handleContentChange(e, "desc")}
          placeholder="აღწერა"
        />

        <Group mt="lg">
          <ListInputForm
            label="მნიშვნელოვანი პუნქტები"
            data={editingService?.content.highlights || []}
            value={editingService?.content.highlights || []}
            onChange={handleHighlightsChange}
            placeholder="დაამატე პუნქტები"
            isMultiSelect
          />
        </Group>

        <Divider />

        <Group mt="md">
          <Button onClick={handleSave}>შენახვა</Button>
        </Group>
      </EditForm>
    </Paper>
  );
};
