import {
  IconNews,
  IconPhoto,
  IconHome,
  IconBadgeAr,
  IconStack,
  IconInfoSquareRounded,
} from "@tabler/icons-react";
import { IFeature } from "./types";
import { BlogControl } from "./features/blogs";
import { Companies } from "./features/companies/CompanyPage";
import { GalleryControl } from "./features/gallery";
import { ServicesControl } from "./features/company_services";
import { Dashboard } from "./features/dashboard/dashboard";
import { CompanyInfoControl } from "./features/company_info";

export const features: IFeature[] = [
  {
    name: "dashboard-core",
    icon: IconHome,
    label: "მთავარი",
    component: <Dashboard />,
  },
  {
    name: "gallery-core",
    icon: IconPhoto,
    label: "ფოტო გალერია",
    component: <GalleryControl />,
  },
  {
    name: "blogs-core",
    icon: IconNews,
    label: "ბლოგი",
    component: <BlogControl />,
  },
  {
    name: "services-core",
    icon: IconBadgeAr,
    label: "სერვისები",
    component: <ServicesControl />,
  },
  {
    name: "company-info",
    icon: IconInfoSquareRounded,
    label: "ჩვენს შესახებ",
    component: <CompanyInfoControl />,
  },
  {
    name: "companies-core",
    icon: IconStack,
    label: "კომპანიების მართვა",
    component: <Companies />,
  },
];
