const initialState = {
  isAuthenticated: false,
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "LOGOUT_SUCCESS":
      return initialState;
    default:
      return state;
  }
};

// Action Creators
export const setAuthenticated = (isAuthenticated: boolean) => ({
  type: "SET_AUTHENTICATED",
  payload: isAuthenticated,
});

export const setUser = (user: any) => ({
  type: "SET_USER",
  payload: user,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const logoutSuccess = () => ({
  type: "LOGOUT_SUCCESS",
});

export const selectUser = (state: any) => state?.user;
