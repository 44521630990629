import { useState, useRef, useEffect } from "react";
import {
  Button,
  Group,
  Text,
  Image,
  rem,
  Modal,
  SimpleGrid,
  Space,
  Box,
  Alert,
} from "@mantine/core";
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconInfoCircle,
} from "@tabler/icons-react";
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";

interface IError {
  code: string | null;
  message: string;
}

interface IProps {
  onUpload?: (selectedFiles: FileWithPath[]) => void;
  maxFiles?: number;
}

const initialErrorState: IError = {
  code: null,
  message: "",
};

const ImageSelector = ({ onUpload, maxFiles = 10 }: IProps) => {
  const [opened, handlers] = useDisclosure(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const dropzoneRef = useRef<any>(null);
  const [fileError, setFileError] = useState<IError>(initialErrorState);

  const alertIcon = <IconInfoCircle />;
  const handleSelectedFiles = (files: FileWithPath[]) => {
    try {
      dropzoneRef.current = files;
      const filesWithURL = files?.map((image) => URL.createObjectURL(image));
      setSelectedFiles(filesWithURL);
    } catch (error) {
      console.error("Problems with handing selected files.", error);
    }
  };

  const handleUpload = () => {
    const files = dropzoneRef.current || [];
    handlers.close();

    // Pass the selected files to the parent component
    if (onUpload && files.length > 0) {
      onUpload(files);
    }
    setSelectedFiles([]);
  };

  useEffect(() => {
    let timer: number | NodeJS.Timeout;
    if (fileError !== null) {
      timer = setTimeout(() => {
        setFileError(initialErrorState);
      }, 3000); // 3 second time interval to show error message
    }
    return () => clearTimeout(timer);
  }, [fileError]);

  return (
    <>
      <Box
        mt="lg"
        style={{
          display: "flex",
          border: "1px dashed var(--mantine-color-blue-7)",
          color: "var(--mantine-color-blue-9)",
          padding: "18px 60px",
          maxWidth: 320,
          minWidth: 270,
          height: 200,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          borderRadius: 8,
        }}
        onClick={handlers.open}
      >
        <IconPhoto
          style={{
            width: rem(52),
            height: rem(52),
            color: "var(--mantine-color-blue-7)",
          }}
          stroke={1.5}
        />
        ფოტოს ატვირთვა
      </Box>

      <Modal
        opened={opened}
        onClose={() => {
          setSelectedFiles([]);
          handlers.close();
        }}
        title=""
        size="xl"
        mah={800}
        padding={32}
      >
        <Dropzone
          ref={dropzoneRef}
          onDrop={handleSelectedFiles}
          onReject={(files) => {
            setFileError(files[0].errors[0]);
          }}
          maxSize={5 * 1024 ** 2}
          accept={IMAGE_MIME_TYPE}
          maxFiles={maxFiles}
        >
          <Group justify="center" gap="xl" mih={220}>
            <Dropzone.Accept>
              <IconUpload
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: "var(--mantine-color-blue-6)",
                }}
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: "var(--mantine-color-red-6)",
                }}
                stroke={1.5}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: "var(--mantine-color-dimmed)",
                }}
                stroke={1.5}
              />
            </Dropzone.Idle>

            <Text size="xl" inline>
              ფოტოს ატვირთვა
            </Text>
          </Group>
          {fileError.code && (
            <Alert
              title="ფაილების ატვირთვისას დაფიქსირდა შეცდომა"
              onClose={() => setFileError(initialErrorState)}
              color="blue"
              variant="light"
              icon={alertIcon}
              style={{ wordWrap: "break-word" }}
            >
              <Text style={{ textWrap: "wrap" }}>{fileError.message}</Text>
            </Alert>
          )}
        </Dropzone>

        <Space h="lg" />

        <SimpleGrid cols={4}>
          {selectedFiles.map((item: any, index: number) => (
            <Image key={index} height={50} mah={100} src={item}></Image>
          ))}
        </SimpleGrid>

        <Space h="md" />

        <Group mt="xl">
          <Button onClick={handleUpload}>შენახვა</Button>
          <Button
            variant="transparent"
            onClick={() => {
              setSelectedFiles([]);
              handlers.close();
            }}
          >
            გაუქმება
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default ImageSelector;
