// hooks/useControlXState.ts
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { features } from "../features";
import {
  ControlXFeatures,
  FeatureStateMapping,
  FeatureNameToReducerKeyMap,
  featureNameToReducerKeyMap,
} from "../types";

type FeatureState<T extends keyof FeatureStateMapping> = FeatureStateMapping[T];

export const useControlXState = <T extends ControlXFeatures>(
  featureName: T
): FeatureState<FeatureNameToReducerKeyMap[T]> => {
  // Find the feature by name
  const feature = features.find((f) => f.name === featureName);

  if (!feature) {
    throw new Error(`Feature with name "${featureName}" not found`);
  }

  // Determine the reducer key using the map
  const reducerKey = featureNameToReducerKeyMap[featureName];

  // Use the useSelector hook to access the specific state slice
  const featureState = useSelector((state: RootState) => state[reducerKey]);

  return featureState as FeatureState<typeof reducerKey>;
};
