import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { HeroSection } from "../../components/HeroSection";
import { ServiceSection } from "../../components/ServiceSection";
import { Reviews } from "../../components/Reviews";
import "./style.css";
import { WorkSection } from "../../components/WorkSection";
import { ContactForm } from "../../components/ContactForm";
import { Footer } from "../../components/Footer";
import FAQ from "../../components/FAQ";

export const HomePage = () => {
  const [device, setDevice] = useState("web");

  useEffect(() => {
    if (window.screen.width < 768) {
      setDevice("mob");
    }
  }, []);

  return (
    <div className="home-page">
      <HeroSection state={device} className="" />
      <ServiceSection device={device} />

      <FAQ isMob={device === "mob"} />

      <Reviews isMob={device === "mob"} />

      <WorkSection isMob={device === "mob"} />

      <ContactForm isMob={device === "mob"} />

      <Footer isMob={device === "mob"} />
    </div>
  );
};
