import React from "react";
import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/tiptap/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dropzone/styles.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createTheme, MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const theme = createTheme({
  // empty
});

root.render(
  <MantineProvider theme={theme}>
    <App />
  </MantineProvider>
);
