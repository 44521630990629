import {
  Container,
  Grid,
  PaperProps,
  SimpleGrid,
  Skeleton,
  Stack,
} from "@mantine/core";
import {
  ErrorAlert,
  LanguageTable,
  MapChart,
  MobileDesktopChart,
  PageHeader,
  SalesChart,
  StatsCard,
  TrafficTable,
} from "../../components";
import { useFetchData } from "../../hooks/useFetchData";

const PAPER_PROPS: PaperProps = {
  p: "md",
  shadow: "md",
  radius: "md",
  style: { height: "100%" },
};

export function Dashboard() {
  const {
    data: statsData,
    error: statsError,
    loading: statsLoading,
  } = useFetchData("/mocks/StatsGrid.json");
  const {
    data: languagesData,
    error: languageError,
    loading: languageLoading,
  } = useFetchData("/mocks/Languages.json");
  const {
    data: trafficData,
    error: trafficError,
    loading: trafficLoading,
  } = useFetchData("/mocks/Traffic.json");

  return (
    <>
      <Container fluid>
        <Stack gap="lg">
          <PageHeader title="Dashboard" withActions={true} />
          <SimpleGrid
            cols={{ base: 1, sm: 2, lg: 2 }}
            spacing={{ base: 10, sm: "xl" }}
            verticalSpacing={{ base: "md", sm: "xl" }}
          >
            {statsError ? (
              <ErrorAlert
                title="Error loading stats"
                message={statsError.toString()}
              />
            ) : (
              <SimpleGrid cols={2}>
                {statsLoading
                  ? Array.from({ length: 4 }).map((o, i) => (
                      <Skeleton
                        key={`stats-loading-${i}`}
                        visible={true}
                        height={200}
                      />
                    ))
                  : statsData?.data?.map((s: any) => (
                      <StatsCard key={s.title} data={s} {...PAPER_PROPS} />
                    ))}
              </SimpleGrid>
            )}
            <MobileDesktopChart {...PAPER_PROPS} />
          </SimpleGrid>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
              <MapChart {...PAPER_PROPS} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <SalesChart {...PAPER_PROPS} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <LanguageTable
                data={languagesData.slice(0, 6)}
                error={languageError}
                loading={languageLoading}
                {...PAPER_PROPS}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
              <TrafficTable
                data={trafficData.slice(0, 6)}
                error={trafficError}
                loading={trafficLoading}
                {...PAPER_PROPS}
              />
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
