import React, { useState } from "react";
import { Logoicon1 } from "../../icons/Logoicon1";
import { Logo } from "../Logo";
import { Menu } from "../Menu";
import { ActionContainer } from "../ActionContainer";
import "./style.css";
import { useDisclosure } from "@mantine/hooks";
import {
  Burger,
  Button,
  Divider,
  Drawer,
  Flex,
  Group,
  NavLink,
  Space,
  Text,
} from "@mantine/core";
import { useNavigate } from "react-router";

const menuItems = [
  { label: "Home", route: "home" },
  { label: "Services", route: "services" },
  { label: "About", route: "about" },
  { label: "Blog", route: "/blogs" },
  // { label: "Log in", route: "login" },
  // { label: "Contact Sales", route: "quote" },
];

export const Header = ({ device }: any) => {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();

  const reloadPage = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const scrollToSection = async (sectionId: string) => {
    if (sectionId.startsWith("/")) {
      await navigate(sectionId);
    } else {
      await navigate("/");
    }

    const section = document.getElementById(sectionId);
    if (section) {
      await window.scrollTo({
        top: section.offsetTop - 85,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="header">
      {device === "web" && (
        <div className="container-web">
          <div onClick={reloadPage}>
            <Logo device="web" className="" />
          </div>
          <Menu device="web" />
          <ActionContainer scrollToSection={scrollToSection} />
        </div>
      )}

      {device === "mob" && (
        <Flex
          justify="space-between"
          flex={1}
          mr={20}
          mt="sm"
          pb="sm"
          align="center"
        >
          <Group onClick={reloadPage}>
            <Logo
              className="logo-instance"
              device="mob"
              icon={<Logoicon1 className="logoicon-1" />}
            />
          </Group>

          <Burger
            opened={opened}
            onClick={toggle}
            aria-label="Toggle navigation"
          />
          <Drawer
            opened={opened}
            position="right"
            onClose={toggle}
            title=""
            size="xs"
            closeButtonProps={{
              icon: <Burger opened />,
            }}
          >
            <Button
              w="100%"
              color="#0d2a7d"
              onClick={() => {
                scrollToSection("quote");
                toggle();
              }}
            >
              Contact Sales
            </Button>

            <Space h="xl" />

            {menuItems.map((item, index) => (
              <>
                <NavLink
                  key={index}
                  label={item.label}
                  onClick={() => {
                    scrollToSection(item.route);
                    toggle();
                  }}
                />
              </>
            ))}

            <Space h="xl" />
            <Divider />
          </Drawer>
        </Flex>
      )}
    </div>
  );
};
