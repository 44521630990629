import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { setLoading, updateCompanyInfo } from "./companyInfo.slice";
import { API } from "../../api";
import { ICompanyInfo } from "./companyInfo.types";

function* fetchCompanyInfoSaga(): SagaIterator {
  try {
    yield put(setLoading(true));
    const companyID = process.env.REACT_APP_COMPANY_ID;
    const response = yield call(API.get, `/companies/${companyID}`);
    yield put(updateCompanyInfo(response.data));
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error fetching company info:", error);
    yield put(setLoading(false));
  }
}

function* updateCompanyInfoSaga(
  action: PayloadAction<ICompanyInfo>
): SagaIterator {
  try {
    yield put(setLoading(true));
    const companyID = process.env.REACT_APP_COMPANY_ID;
    if (!companyID) {
      console.error(
        "Attempting to update company info without a valid ID:",
        action.payload
      );
      return;
    }

    const response = yield call(
      API.put,
      `/companies/${companyID}`,
      action.payload
    );
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error updating company info:", error);
    yield put(setLoading(false));
  }
}

export function* initCompanyInfoSagas() {
  yield takeLatest(updateCompanyInfo.type, updateCompanyInfoSaga);

  yield call(fetchCompanyInfoSaga);
}
