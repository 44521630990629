import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBlog, IBlogState } from "./blog.types";

// Initial state
const initialState: IBlogState = {
  blogs: [],
  loading: false,
  selectedBlogID: "",
  activeBlog: {
    title: "",
    content: "",
    published: false,
  },
};

// Create slice
const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    fetchBlogs() {
      // This is just a trigger for the fetchBlogsSaga
    },
    createBlog: (state, action: PayloadAction<IBlog>) => {
      state.blogs.push(action.payload);
    },
    readBlog: (state, action: PayloadAction<string>) => {
      state.activeBlog = state.blogs.find(
        (blog: IBlog) => blog.id === action.payload
      );
    },
    updateBlog: (state, action: PayloadAction<IBlog>) => {
      const index = state.blogs.findIndex(
        (blog) => blog.id === action.payload.id
      );
      if (index !== -1) {
        state.blogs[index] = action.payload;
      } else {
        console.warn("Attempted to update a blog that doesn't exist");
      }
    },
    deleteBlog(state, action: PayloadAction<string>) {
      state.blogs = state.blogs.filter((blog) => blog.id !== action.payload);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedBlogID(state, action: PayloadAction<string>) {
      state.selectedBlogID = action.payload;
    },
    setBlogs(state, action: PayloadAction<IBlog[]>) {
      state.blogs = action.payload;
    },
    setBlogCoverImageURL(state, action: PayloadAction<string>) {
      if (state.activeBlog) {
        state.activeBlog.cover_image_url = action.payload;
      }
    },
  },
});

export const blogReducer = blogSlice.reducer;
export const {
  fetchBlogs,
  setLoading,
  setBlogs,
  setSelectedBlogID,
  createBlog,
  readBlog,
  updateBlog,
  deleteBlog,
  setBlogCoverImageURL,
} = blogSlice.actions;
