import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  createBlog,
  fetchBlogs,
  deleteBlog,
  setBlogs,
  setLoading,
  updateBlog,
  readBlog,
} from "./blog.slice";
import { IBlog, IBlogState } from "./blog.types";
import { API } from "../../api";

function* fetchBlogsSaga(): SagaIterator {
  try {
    yield put(setLoading(true));
    const response = yield call(API.get, "/blogs");
    yield put(setBlogs(response.data));
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error fetching blogs:", error);
    yield put(setLoading(false));
  }
}

function* createBlogSaga(action: PayloadAction<IBlog>): SagaIterator {
  try {
    yield put(setLoading(true));
    const response = yield call(API.post, "/blogs", action.payload);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error creating blog:", error);
    yield put(setLoading(false));
  }
}

function* readBlogSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoading(true));

    const blogs = yield select((state: IBlogState) => state.blogs);
    // const blog = blogs.find((blog: IBlog) => blog.id === action.payload);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error readBlogSaga blog:", error);
    yield put(setLoading(false));
  }
}

function* updateBlogSaga(action: PayloadAction<IBlog>): SagaIterator {
  try {
    if (!action.payload.id) {
      console.error(
        "Attempting to update a blog without a valid ID:",
        action.payload
      );
      return;
    }
    yield put(setLoading(true));
    const response = yield call(
      API.put,
      `/blogs/${action.payload.id}`,
      action.payload
    );
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error updating blog:", error);
    yield put(setLoading(false));
  }
}

function* deleteBlogSaga(action: PayloadAction<number>): SagaIterator {
  try {
    yield put(setLoading(true));
    yield call(API.delete, `/blogs/${action.payload}`);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error removing blog:", error);
    yield put(setLoading(false));
  }
}

export function* initBlogSagas() {
  yield takeLatest(fetchBlogs.type, fetchBlogsSaga);

  yield takeLatest(createBlog.type, createBlogSaga);
  yield takeLatest(readBlog.type, readBlogSaga);
  yield takeLatest(updateBlog.type, updateBlogSaga);
  yield takeLatest(deleteBlog.type, deleteBlogSaga);

  yield call(fetchBlogsSaga);
}
