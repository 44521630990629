import {
  Button,
  Center,
  Container,
  Flex,
  SegmentedControl,
  Space,
  Switch,
} from "@mantine/core";
import {
  IconArrowNarrowLeft,
  IconDeviceFloppy,
  IconEdit,
  IconEye,
  IconTrash,
} from "@tabler/icons-react";
import { IBlogToolbarProps } from "./blog.types";

export const BlogToolBar = ({
  onBack,
  onPreview,
  onEdit,
  onSave,
  onDelete,
  onPublish,
  isPublished,
}: IBlogToolbarProps) => {
  return (
    <Container
      h={50}
      bg="#F2F3F5"
      style={{
        borderRadius: 100,
      }}
    >
      <Flex mih={50} bg="#F2F3F5" justify="space-between" align="center">
        <Button
          size="sm"
          style={{
            borderRadius: 100,
          }}
          onClick={onBack}
          variant="white"
        >
          <IconArrowNarrowLeft color="#000C25" />
        </Button>

        <Space w="xl" />
        <Space w="xl" />
        <Space w="xl" />

        <SegmentedControl
          data={[
            {
              value: "edit",
              label: (
                <Center style={{ gap: 10 }} onClick={onEdit}>
                  <IconEdit />
                  <span>რედაქტირება</span>
                </Center>
              ),
            },
            {
              value: "preview",
              label: (
                <Center style={{ gap: 10 }} onClick={onPreview}>
                  <IconEye />
                  <span>ჩვენება</span>
                </Center>
              ),
            },
          ]}
        />
        <Button
          variant="transparent"
          color="#000C25"
          leftSection={<IconDeviceFloppy />}
          onClick={onSave}
        >
          შენახვა
        </Button>

        <Button
          variant="transparent"
          color="#E9143B"
          leftSection={<IconTrash />}
          onClick={onDelete}
        >
          წაშლა
        </Button>

        <Button
          variant="transparent"
          color="#000C25"
          rightSection={
            <Switch
              color="#1FD202"
              size="lg"
              onLabel="ON"
              offLabel="OFF"
              onClick={onPublish}
              checked={isPublished}
            />
          }
        >
          გამოქვეყნება
        </Button>
      </Flex>
    </Container>
  );
};
