import React, { useState, useEffect } from "react";
import { API } from "../../api";
import * as SC from "./styles";
import ImageSelector from "../../components/ImageSelector/ImageSelector";
import { FileWithPath } from "@mantine/dropzone";
import {
  Button,
  Container,
  Group,
  Image,
  List,
  MultiSelect,
  SimpleGrid,
  Space,
  Stack,
  Switch,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { features } from "../../features";
import { IconCircleCheck, IconPlus } from "@tabler/icons-react";
import { ImageViewer } from "../../components";

export const Companies = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [newCompany, setNewCompany] = useState({
    name: "",
    website: "",
    description: "",
    logo_url: "",
    features: [] as any,
  });
  const [editingId, setEditingId] = useState(null);
  const [img, setImg] = useState<FileWithPath | null>(null);

  // Fetch companies
  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await API.get("/companies");
      setCompanies(response.data);
    } catch (error) {
      console.error("Failed to fetch companies:", error);
    }
  };

  // Handle form changes
  const handleChange = (e: any) => {
    if (Array.isArray(e)) {
      setNewCompany({ ...newCompany, features: e });
    } else {
      const { name, value } = e.target;
      setNewCompany({ ...newCompany, [name]: value });
    }
  };

  // Create or update company
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _imgURL = await saveImage(img);

    try {
      if (editingId) {
        await API.put(`/companies/${editingId}`, {
          ...newCompany,
          features: newCompany.features,
          logo_url: _imgURL,
        });
      } else {
        await API.post("/companies", {
          ...newCompany,
          features: newCompany.features,
          logo_url: _imgURL,
        });
      }
      fetchCompanies(); // Refresh the list
      setNewCompany({
        name: "",
        website: "",
        description: "",
        logo_url: "",
        features: [],
      }); // Reset form
      setEditingId(null); // Exit editing mode
    } catch (error) {
      console.error("Failed to submit company:", error);
    }
  };

  // Populate form for editing
  const editCompany = (company: any) => {
    setShowAddNew(true);
    setNewCompany({
      name: company.name,
      website: company.website,
      description: company.description,
      logo_url: company.logo_url,
      features: company.features,
    });
    setEditingId(company.id);
  };

  // Delete company
  const deleteCompany = async (id: string) => {
    try {
      await API.delete(`/companies/${id}`);
      fetchCompanies(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete company:", error);
    }
  };

  const deleteImage = (name: any) => {
    setImg(null);
    if (name) {
      API.delete(`/images/${name}`)
        .then((response) => {
          console.log("Image deleted successfully:", response.data);
        })
        .catch((error) => {
          console.log("Error deleting image:", error);
        });
    }
  };

  const saveImage = async (img: any) => {
    try {
      const formData = new FormData();
      formData.append("file", img);

      const response = await API.post("/images/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        console.log("Image uploaded successfully:", response.data);
      }

      const publishedURL = response.data?.url;

      return publishedURL;
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  return (
    <Container>
      <Button
        size="md"
        leftSection={<IconPlus />}
        onClick={() => setShowAddNew(!showAddNew)}
        style={{ marginBottom: "20px" }}
      >
        Add New Company
      </Button>

      {showAddNew && (
        <Stack>
          <Title order={2}>Add A New Company</Title>
          <SC.Form onSubmit={handleSubmit}>
            <SC.Input
              type="text"
              name="name"
              placeholder="Name"
              value={newCompany.name}
              onChange={handleChange}
              required
            />
            <SC.Input
              type="text"
              name="website"
              placeholder="Website"
              value={newCompany.website}
              onChange={handleChange}
              required
            />
            <SC.TextArea
              name="description"
              placeholder="Description"
              value={newCompany.description}
              onChange={handleChange}
            />

            <MultiSelect
              label="Choose features"
              placeholder="Pick multiple values"
              data={features.map((item) => item.name)}
              value={newCompany.features}
              onChange={handleChange}
            />

            <Space h="lg" />

            <Title order={3}>Select Company Logo</Title>
            <Group>
              <ImageSelector
                onUpload={(_img) => setImg(_img[0])}
                maxFiles={1}
              />
            </Group>

            <Space h="lg" />

            <SC.Button type="submit">
              {editingId ? "Update" : "Create"}
            </SC.Button>
          </SC.Form>
        </Stack>
      )}

      <SC.CompanyList>
        {companies.map((company: any) => (
          <SC.CompanyItem key={company.id}>
            <SC.CompanyTitle>{company.name}</SC.CompanyTitle>
            <SC.CompanyInfo>{company.website}</SC.CompanyInfo>
            <SC.CompanyInfo>{company.description}</SC.CompanyInfo>
            <SC.CompanyInfo>ID: {company.id}</SC.CompanyInfo>
            <Space h="md" />
            <SC.CompanyInfo>Logo URL: {company.logo_url}</SC.CompanyInfo>
            <div
              style={{
                width: 200,
                height: "100%",
              }}
            >
              <Image src={company.logo_url} alt={company.name} />
            </div>
            <Space h="md" />

            <Stack justify="center" align="center">
              <Text>Enabled Features:</Text>
              <List
                icon={
                  <ThemeIcon color="teal" size={20} mt="sm" radius="xl">
                    <IconCircleCheck />
                  </ThemeIcon>
                }
              >
                {company.features.map((feature: any, index: number) => (
                  <List.Item key={index}>{feature}</List.Item>
                ))}
              </List>
            </Stack>

            <Space h="md" />

            <SC.Button onClick={() => editCompany(company)}>Edit</SC.Button>
            <SC.Button onClick={() => deleteCompany(company.id)}>
              Delete
            </SC.Button>
          </SC.CompanyItem>
        ))}
      </SC.CompanyList>
    </Container>
  );
};
