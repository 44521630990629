import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Shell } from "./Shell";

export const protectedRoutes = [{ route: "/admin", component: <Shell /> }];

export const ProtectedRoute = ({ children }: any) => {
  const isAuthenticated = useSelector(
    (state: any) => state.user?.isAuthenticated
  );
  const location = useLocation();

  const accessToken = localStorage.getItem("user/accessToken");

  if (!isAuthenticated && !accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
