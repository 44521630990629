import { useEffect, useState } from "react";
import { BlogCard } from "../../components/BlogCard";
import {
  Button,
  Container,
  Flex,
  SimpleGrid,
  Space,
  Text,
} from "@mantine/core";
import { BlogEditor } from "./blog.editor";
import { useDispatch, useSelector } from "react-redux";
import { IBlogState, fetchBlogs, readBlog, setSelectedBlogID } from "../blogs";
import { IconPlus } from "@tabler/icons-react";

export const BlogControl = () => {
  const [editorMode, setEditorMode] = useState(false);
  const dispatch = useDispatch();
  const { blogs, loading, selectedBlogID } = useSelector(
    (state: { blogs: IBlogState }) => state.blogs
  );

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  const handleToggleEditor = (id: string) => {
    dispatch(setSelectedBlogID(id));
    dispatch(readBlog(id));
    setEditorMode(true);
  };

  return (
    <Container fluid pb="xl">
      {!editorMode && (
        <Container fluid>
          <Flex justify="space-between" align="center">
            <Text size="lg">ბლოგები</Text>
            <Button
              size="md"
              leftSection={<IconPlus />}
              onClick={() => handleToggleEditor("")}
            >
              ახალი ბლოგი
            </Button>
          </Flex>

          <Space h="xl" />

          <SimpleGrid cols={3}>
            {blogs.map((blog: any, index: number) => (
              <BlogCard
                key={index}
                title={blog.title}
                text={blog.content}
                onClick={() => {
                  handleToggleEditor(blog.id);
                }}
                imgURL={blog.cover_image_url}
                isPublished={blog.published}
              />
            ))}
          </SimpleGrid>
        </Container>
      )}

      {editorMode && (
        <BlogEditor
          selectedBlogID={selectedBlogID}
          setEditorMode={setEditorMode}
        />
      )}
    </Container>
  );
};
