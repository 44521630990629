import React from "react";
import "./style.css";
import { useNavigate } from "react-router";

export const Button = ({ style }) => {
  const navigate = useNavigate();

  const handleLogIn = () => {
    navigate("/login");
  };

  const scrollToSection = async (sectionId) => {
    await navigate("/home");
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 85,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className={`button style-${style}`}>
      <a className="label">
        {["default", "outline"].includes(style) && (
          <div onClick={() => scrollToSection("quote")}>Contact Sales</div>
        )}

        {style === "link" && <a onClick={handleLogIn}>Log In</a>}
      </a>
    </div>
  );
};
