import React, { useEffect, useState } from "react";
import { HomePage } from "./pages/Home";
import BlogList from "./pages/Blog/BlogList";
import BlogPost from "./pages/Blog/BlogPost";
import { ControlX, Page } from "@etlyn/control-x";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

function App() {
  const [device, setDevice] = useState("web");

  useEffect(() => {
    if (window.screen.width < 768) {
      setDevice("mob");
    }
  }, []);
  return (
    <ControlX header={<Header device={device} />}>
      <Page route="/" component={<HomePage />} />
      <Page route="/home" component={<HomePage />} />
      <Page route={"/blogs"} component={<BlogList />} />
      <Page route="/blogs/:blogID" component={<BlogPost />} />
    </ControlX>
  );
}

export default App;
