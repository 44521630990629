import React from "react";
import { TextInput, Button, Grid, Textarea } from "@mantine/core";
import { ICompanyInfo } from "./companyInfo.types";

interface CompanyInfoFormProps {
  formData: Partial<ICompanyInfo>;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleSave: () => void;
  handleArrayChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => void;
  handleArrayAdd: (field: string) => void;
  handleArrayRemove: (index: number, field: string) => void;
}

const CompanyInfoForm: React.FC<CompanyInfoFormProps> = ({
  formData,
  handleInputChange,
  handleSave,
  handleArrayChange,
  handleArrayAdd,
  handleArrayRemove,
}) => {
  return (
    <Grid>
      <Grid.Col span={6}>
        <TextInput
          label="Name"
          name="name"
          value={formData.name || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Website"
          name="website"
          value={formData.website || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Textarea
          label="Description"
          name="description"
          value={formData.description || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          label="Logo URL"
          name="logo_url"
          value={formData.logo_url || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Contact Email"
          name="contact_email"
          value={formData.contact_email || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Contact Number"
          name="contact_number"
          value={formData.contact_number || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Service Email"
          name="service_email"
          value={formData.service_email || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Service Number"
          name="service_number"
          value={formData.service_number || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          label="Location"
          name="location"
          value={formData.location || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        {formData.social_media?.map((social, index) => (
          <div key={index}>
            <TextInput
              label="Social Media Platform"
              name={`social_media[${index}].platform`}
              value={social.platform}
              onChange={(e) => handleArrayChange(e, index, "social_media")}
            />
            <TextInput
              label="Social Media URL"
              name={`social_media[${index}].url`}
              value={social.url}
              onChange={(e) => handleArrayChange(e, index, "social_media")}
            />
            <Button onClick={() => handleArrayRemove(index, "social_media")}>
              Remove
            </Button>
          </div>
        ))}
        <Button onClick={() => handleArrayAdd("social_media")}>
          Add Social Media
        </Button>
      </Grid.Col>
      <Grid.Col span={12}>
        {formData.seo_keywords?.map((keyword, index) => (
          <div key={index}>
            <TextInput
              label={`SEO Keyword ${index + 1}`}
              name={`seo_keywords[${index}]`}
              value={keyword}
              onChange={(e) => handleArrayChange(e, index, "seo_keywords")}
            />
            <Button onClick={() => handleArrayRemove(index, "seo_keywords")}>
              Remove
            </Button>
          </div>
        ))}
        <Button onClick={() => handleArrayAdd("seo_keywords")}>
          Add SEO Keyword
        </Button>
      </Grid.Col>
      <Grid.Col span={12}>
        <Textarea
          label="Metadata"
          name="metadata"
          value={JSON.stringify(formData.metadata, null, 2) || ""}
          onChange={handleInputChange}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Button onClick={handleSave}>Save</Button>
      </Grid.Col>
    </Grid>
  );
};

export default CompanyInfoForm;
